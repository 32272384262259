import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { CONFIGURATION, Configuration } from "src/configuration";
import { Observable, of, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { catchError, concatMap, map, shareReplay } from "rxjs/operators";

const apiErrorCodes = {
  unableToGetUserPrincipalName: 1001,
  badAuthToken: 1003,
  noDesktopRolesAssigned: 1004,
  unableToResolveInfrastructureContext: 1013,
  unableToProceedFurtherDueToMaintenance: 1014,
  errorStartingAppStreamSession: 1015,
  appStreamFeatureNotAvailable: 2015,
};

const apiErrorsMap = Object.fromEntries(Object.entries(apiErrorCodes).map((a) => a.reverse()));

@Injectable({
  providedIn: "root",
})
export class AppStreamService {
  private featureEnabled$: Observable<boolean> | null;
  private errorKey: string | null = null;

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
    private translate: TranslateService
  ) {}

  get appstreamBaseUrl(): Observable<string> {
    if (!this.configuration.apis.appstream) {
      return throwError(
        () => ({ error: apiErrorCodes.appStreamFeatureNotAvailable } as HttpErrorResponse)
      );
    }
    return of(this.configuration.apis.appstream);
  }

  get appStreamErrorKey(): Observable<string | null> {
    return this.errorKey ? of(this.errorKey) : of(null);
  }

  featureEnabled(): Observable<boolean> {
    if (this.featureEnabled$) {
      return this.featureEnabled$;
    }

    this.featureEnabled$ = this.appstreamBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/featurestatus`, { observe: "response" })),
      catchError((error) => {
        const errorCode = parseInt(error.error, 10);
        if (
          [
            apiErrorCodes.appStreamFeatureNotAvailable,
            apiErrorCodes.unableToResolveInfrastructureContext,
            apiErrorCodes.unableToProceedFurtherDueToMaintenance,
          ].includes(errorCode)
        ) {
          if (errorCode === apiErrorCodes.unableToProceedFurtherDueToMaintenance) {
            const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
            this.errorKey = `errors.api.${apiErrorTranslationKey}`;
          }
          return of(false);
        }
        return throwError(() => error);
      }),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => response.status === 200), //eslint-disable-line @typescript-eslint/no-explicit-any
      shareReplay()
    );

    return this.featureEnabled$;
  }

  handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse> {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(() => `Error: ${error.error.message}`);
    } else {
      // Server-side errors
      if (error.status === 404) {
        return of(null);
      }
      const errorCode = parseInt(error.error, 10);
      const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
      return this.translate.get(`errors.api.${apiErrorTranslationKey}`).pipe(
        map((message: string) => {
          throw new Error(message);
        })
      );
    }
  }

  getUserStatus(): Observable<UserStatus> {
    return this.appstreamBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get(`${url}/appstream/user`, {
          observe: "response",
        })
      ),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (response === null || response.status === 404) {
          return UserStatus.NoUser;
        }

        if (response.status === 202) {
          return UserStatus.Waiting;
        }

        if (response.status === 200) {
          return UserStatus.Ready;
        }

        return UserStatus.Waiting;
      })
    );
  }

  listAppStreamFleetsStacks(): Observable<AppStreamFleetStack[]> {
    return this.appstreamBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<AppStreamFleetStack[]>(`${url}/appstream`)),
      catchError(this.handleError.bind(this))
    );
  }

  startAppStream(appStreamFleetStackId: string): Observable<string> {
    return this.appstreamBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.post(
          `${url}/appstream/start-domain/${appStreamFleetStackId}`,
          {},
          { responseType: "text" }
        )
      ),
      catchError(this.handleError.bind(this))
    );
  }
}

export interface AppStreamFleetStack {
  id: string;
  infrastructureTenantId: string;
  name: string;
  fleetName: string;
  stackName: string;
  instanceKind: string;
  availableSeats: number;
  instanceType: string;
  state: string;
}

export enum UserStatus {
  NoUser = "NoUser",
  Waiting = "Waiting",
  Ready = "Ready",
}
